import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  QuickFacts,
  BannerLinks,
  BannerSmall,
  CallUs,
  ProjectPagination,
  Image
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import contacts from '../../components/CallUs/DefaultContacts'

import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/header.energietirol.png'
import BannerImageMobile from '../../assets/headermobil.energietirol.png'
import image4 from '../../assets/bannerlinks/bannerbox.ikb.jpg'
import image5 from '../../assets/stadtamthall.uebersicht.jpg'


export const frontmatter = {
  title: 'Energietirol.js',
  slug: '/Projekte/Energietirol.js'
}

const EnergieTirol = () => {
  const links = generateBreadcrumbsLinks('/projekte/energietirol', 'Energie Tirol')
  return (
    <Layout margin={false} color="white">
    <Helmet
      title="Energie Tirol"
      meta={[
        {
          name: 'description',
          content:
            'Die Energie Tirol mit ihrer tollen neuen WebApp!'
        },
        {
          name: 'keywords',
          content:
            'Energie Tirol holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Webapplikation, Energie Tirol, Tirol, Projektverwaltungstool'
        },
        {
          property: 'og:description',
          content:
            'Die Energie Tirol mit ihrer tollen neuen WebApp!'
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/header.energietirol-6c509f5cbc3d2b1818687100e6ba9090.png'
        }
      ]}
    />
     <BannerSmall color="black" image={BannerImage} title="Energie Tirol"/>
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            JA zur effizienter Kunden- und Projektverwaltung:
          </div>
        }
        text={
          <span>
            <i>
              <p>Energie Tirol ist DIE unabhängige Beratungsstelle des Landes Tirol und DER kompetente Ansprechpartner für alle Energiefragen.</p>
              <p> Sie bereiten Informationen über nachhaltige und moderne Energielösungen verständlich auf und erarbeiten praktische Umsetzungsmöglichkeiten.</p>
              <p> Energie Tirol unterstützt Privatpersonen, Gemeinden sowie auch Unternehmen, individuelle Energiesparpotenziale zu identifizieren und umzusetzen.</p>
            </i>
          </span>
        }
      />
         
      <TextBox
        title={false}
        text={
          <span>
            <h2>Projektverwaltung? <br/> Für Energie Tirol - ein Kinderspiel!</h2>
            <p>Mit der von uns entwickelten Projekte- und Kundenverwaltungs-WebApplikation, ist das Projektmanagement für den Verein Energie Tirol ein Kinderspiel.</p>
            <p>In enger Kollaboration haben wir zusammen ein Tool entwickelt, dass die Arbeits- und Projektverwaltungsprozesse effizienter gestaltet. Interne Kommunikation wird durch den für jeden Mitarbeiter gewährten Zugang zur Plattform, vereinfacht und gefördert.</p>
            <p>Die Grunddaten, laufenden Daten und die Aktivitätsprotokolle der Gemeinden und der Gemeindeprojekte können hier daher optimal verwaltet und administriert werden. Durch einen automatisch generierten Bericht ist auch ein schneller Gesamtüberblick über die einzelnen Projekte oder Gemeinden per Knopfdruck erhältlich.</p>
            <p>Bei 281 zu verwaltenden Gemeinden mit jeweils mehreren Projekten ist ein solches Tool definitiv nicht mehr weg zu denken.</p>
          </span>
        }
      />

<QuickFacts
        facts={[
          { count: 281, suffix: '', text: 'Gemeinden' },
          { count: 777, suffix: '', text: 'laufende Projekte' },
          { count: 405, suffix: '', text: 'Veranstaltungen' }
        ]}
        color="orange"
      />

<TextBox
        title={false}
        text={
          <span>
            <h2>Unternehmensprozesse digitalisieren? Aber wie?</h2>
            <p>
              Mit holzweg GmbH® natürlich. Wir bauen individuelle Software für Ihr Unternehmen. Wir passen die Software an Sie an, nicht umgekehrt! Klingt gut? Ist gut und schaut gut aus :)
            </p>
          </span>
        }
        buttons={[
          {link: '/individual-software', text: 'Individual-Software'}
      ]}
      />
    
      <CallUs contacts={contacts} title="Gerne für Sie da" />
      <TextBox titleMods={["center"]}
        title="Weitere Projekte"
        text={false}
      />
      <ProjectPagination
        titleLeft="IKB"
        imageLeft={image4}
        leftLink="/projekte/ikb"
        titleRight="Stadtamt Hall Relaunch"
        imageRight={image5}
        rightLink="/projekte/stadtamthall"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default EnergieTirol
